<template>
  <v-container class="issue-cred px-0">
    <v-row>
      <v-col cols="6" class="">
        <h2 class="title_header pr-4">
          {{ $t('issue.title') }}
        </h2>
        <div class="steps">
          {{ $t('signup.step[0]') }} {{ step + 1 }} {{ $t('signup.step[1]') }} 2
        </div>
      </v-col>
      <v-col cols="6" class="text-right pr-0">
        <v-btn v-if="step == 1" class="back mr-4" @click="back()">{{
          $t('button.back')
        }}</v-btn>
        <v-btn class="next" :loading="sending" @click="nextStep()">
          {{ buttonText }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card flat class="pa-0 mt-6 issue-form-wrapper">
      <v-row>
        <v-col cols="4" class="pt-0" style="max-height: 55vh; overflow-y: auto">
          <v-stepper v-model="step" elevation="0">
            <v-stepper-items>
              <v-stepper-content step="0" class="pa-3">
                <v-container class="">
                  <v-row>
                    <v-col
                      v-if="templateValuesBack.length > 0"
                      cols="12"
                      class=""
                    >
                      <p class="mb-0 side-header">
                        {{ $t('createCertModal.front') }}
                      </p>
                    </v-col>
                    <v-col
                      v-for="(field, index) in templateValuesFront"
                      :key="field._id"
                      cols="12"
                      class="input-field pb-0"
                    >
                      <label class="">
                        {{
                          field.attr == 'IMAGE' && field.type == 'image'
                            ? $t('issue.labelUpload')
                            : field.attr
                        }}

                        <span
                          v-if="field.isMandatory == 'true'"
                          style="color: #e95e5e"
                        >
                          *
                        </span>
                      </label>
                      <v-text-field
                        v-if="isNFT() && field.attr == 'BACKGROUND'"
                        v-model="templateValuesFront[index].value"
                        class="mt-2"
                        :type="field.type"
                        flat
                        solo
                        @input="
                          clearError(index);
                          changeBackground($event, index);
                        "
                        :maxlength="field.maxCharacters"
                        :counter="field.maxCharacters"
                        :error="templateValuesFront[index].error"
                      ></v-text-field>
                      <v-text-field
                        v-else-if="field.type !== 'image'"
                        v-model="templateValuesFront[index].value"
                        class="mt-2"
                        :type="field.type"
                        flat
                        solo
                        @input="
                          clearError(index);
                          replaceAttributeText(field);
                        "
                        :maxlength="field.maxCharacters"
                        :counter="field.maxCharacters"
                        :error="templateValuesFront[index].error"
                      ></v-text-field>
                      <v-btn
                        v-show="field.type == 'image'"
                        text
                        :loading="imageLoading"
                        class="advance-btn upload my-5"
                        @click="uploadImage(field, field.indexArray.indexAttr)"
                      >
                        {{ $t('issue.buttonUpload') }}
                      </v-btn>

                      <input
                        v-if="field.type == 'image'"
                        type="file"
                        :ref="'uploadImage'"
                        style="display: none"
                        accept="image/*"
                        @change="newImage($event, field)"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="templateValuesBack.length > 0"
                      cols="12"
                      class=""
                    >
                      <p class="mb-0 side-header">
                        {{ $t('createCertModal.back') }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="(field, index) in templateValuesBack"
                      :key="field._id"
                      cols="12"
                      class="input-field pb-0"
                    >
                      <label class=""
                        >{{ field.attr }}

                        <span
                          v-if="field.isMandatory == 'true'"
                          style="color: #e95e5e"
                        >
                          *
                        </span>
                      </label>
                      <v-text-field
                        v-model="templateValuesBack[index].value"
                        class="mt-2"
                        :type="field.type"
                        flat
                        solo
                        @input="
                          clearError(index);
                          replaceAttributeText(field);
                        "
                        :error="templateValuesBack[index].error"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>

              <v-stepper-content step="1" class="pa-3">
                <v-container class="">
                  <v-row>
                    <v-col v-if="false" cols="12" class="input-field pb-0">
                      <label class=""> {{ $t('issue.wallet') }}</label>
                      <v-radio-group v-model="hasWallet" row>
                        <v-radio
                          color="#009fb1"
                          :label="$t('issue.radio[0]')"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          color="#009fb1"
                          :label="$t('issue.radio[1]')"
                          :value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col
                      cols="12"
                      v-show="hasWallet == 1"
                      class="input-field pb-0"
                      style="padding-right: 10px"
                    >
                      <label class=""> {{ $t('issue.walletField') }}</label>
                      <v-text-field
                        v-model="walletAddress"
                        class="mt-2"
                        flat
                        solo
                        :error="errorMail"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      v-show="hasWallet == 0"
                      class="input-field pb-0"
                      style="padding-right: 10px"
                    >
                      <label class=""> {{ $t('issue.emailField') }}</label>
                      <v-text-field
                        v-model="email"
                        class="mt-2"
                        flat
                        persistent-hint
                        :rules="emailRules"
                        :hint="$t('issue.emailHint')"
                        :error-messages="errorMail"
                        solo
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
        <v-col cols="8" class="pt-0 template-background">
          <v-row>
            <v-col
              v-show="customTemplateName == 'templateEditor'"
              cols="12"
              class="pt-6"
            >
              <Canvas
                v-if="reload || frontend_props.currentLayout == 'Card'"
                :editable="false"
                :width="
                  sending && frontend_props.currentLayout !== 'Card'
                    ? 1080
                    : 480
                "
                :style="canvasStyle"
              >
              </Canvas>
            </v-col>
            <v-col
              v-if="customTemplateName !== 'templateEditor' && isLoaded"
              cols="12"
              class="static_image pt-0"
            >
              <CustomCard
                :frontTemplate="frontTemplate"
                :backTemplate="backTemplate"
                :tableValues="tableValues"
                :templateValues="templateValuesFront"
                :caName="caName"
                :credentialName="credName"
                :urlPhoto="photoURLBase64"
                :editable="editable"
                :frontend_props="frontend_props"
                @removeItem="removeItem"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <MessageModal
      v-if="showSuccessModal"
      @close="$router.go(-1)"
      :message="this.email ? 'email' : 'wallet'"
    ></MessageModal>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import CustomCard from '../../components/CustomCard';
import { ISSUE_USER, UPLOAD_FILE } from '../../store/actions';

import Canvas from '../../components/TemplateEditor/components/Canvas/Canvas';

import MessageModal from '../../components/TemplateEditor/modals/MessageModal';

import { generateImages } from '@/plugins/snapshot';

const KEYVAL = 'keyval';
const TABLE = 'table';
const LOGOS = 'logos';
const SIGS = 'sigs';
const API_URL_IMAGES = process.env.VUE_APP_API_URL_IMAGES;

export default {
  name: 'IssueCredential',
  components: {
    CustomCard,
    MessageModal,
    Canvas,
  },
  computed: {
    ...mapGetters(['adminEmail', 'cid', 'renderedDimensions']),
    ...mapGetters('template', [
      'frontend_props',
      'tid',
      'caName',
      'credName',
      'urlPhoto',
      'cardHeaders',
      'templateItens',
      'customTemplateName',
    ]),
    canvasStyle() {
      return 'max-height: 60vh;';
    },
  },
  watch: {
    tid(value) {
      if (value) {
        this.initialLoad();
      }
    },
    hasWallet(value) {
      if (value == 1) {
        this.email = null;
      } else if (value == 0) {
        this.walletAddress = null;
      }
    },
  },
  beforeDestroy() {
    // Causes error in templatecreator
    this.resetTemplateValues();
    window.onbeforeunload = null;
  },
  mounted() {
    window.onbeforeunload = function () {
      return 'Are you sure you want to close the window?';
    };
    this.$log.debug('mounted() tid: ', this.tid);
    if (this.tid) {
      this.initialLoad();
    }
  },
  methods: {
    changeBackground(e, index) {
      this.frontend_props.backgroundFront = e;
      this.templateValuesFront[index].value = e;
    },
    addStylesheetURL(fonts) {
      let url = `https://fonts.googleapis.com/css2?family=${fonts[0]}`;

      console.log(fonts);

      for (let index = 1; index < fonts.length; index++) {
        const element = fonts[index];
        console.log(element);
        url = url.concat(`&family=${element}`);
      }
      url = url.concat(`&display=swap`);

      console.log(url);

      var link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = url;
      link.crossOrigin = 'anonymous';
      document.getElementsByTagName('head')[0].appendChild(link);
    },
    uploadPhoto(file, folder) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(UPLOAD_FILE, { file, folder })
          .then((url) => {
            resolve(url);
          })
          .catch((err) => {
            this.logError('uploadPhotos', err);
            reject(err);
          });
      });
    },
    uploadImage(field, index) {
      this.debug('uploadImage function', field, index);
      this.$log.debug(this.$refs);
      this.$refs['uploadImage'][index].click();
    },
    async newImage(event, field) {
      // field.onload = () => {
      //   URL.revokeObjectURL(field.src); // no longer needed, free memory
      // };

      // field.value = URL.createObjectURL(event.target.files[0]);
      // field.file = event.target.files[0];
      this.imageLoading = true;
      field.value = await this.$store
        .dispatch(UPLOAD_FILE, {
          file: event.target.files[0],
          folder: `${this.tid}`,
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
      this.imageLoading = false;

      this.replaceAttributeImage(field);

      // this.uploadPhoto(event.target.files[0], this.tid).then((url) => {
      //   field.value = url;
      //   this.replaceAttributeImage(field);
      // });
      // this.$log.debug(field);
    },
    async initialLoad() {
      this.debug('IssueCertificate: ', this.customTemplateName);
      if (this.customTemplateName == 'templateEditor') {
        this.debug('frontend_props', this.frontend_props);

        this.createTemplateItems();

        this.$store.dispatch('enableBackSide', false);

        if (this.frontend_props.backgroundBack) {
          if (this.pages.length == 1) {
            this.pages.push('#back');
          }
          this.$store.dispatch('enableBackSide', true);
        }

        // copy template values to reset when beforeDestroy() is called
        this.templateCopy = JSON.parse(JSON.stringify(this.frontend_props));

        this.components = this.frontend_props.components;

        await this.splitBaseTextIntoArray();

        // this.addStylesheetURL([...this.currentFonts]);

        this.frontend_props.backgroundFront = await this.toDataURL(
          this.frontend_props.backgroundFront
        );
        if (this.frontend_props.backgroundBack)
          this.frontend_props.backgroundBack = await this.toDataURL(
            this.frontend_props.backgroundBack
          );

        this.$store.dispatch('setTemplate', this.frontend_props);
        this.$store.commit('setActiveComponent', '');
        this.isLoaded = true;
        this.$forceUpdate();
      } else {
        this.createOldTemplate();
        this.replaceImagesURL();
      }
    },
    resetTemplateValues() {
      this.debug('START reset FUNCTION');
      // for (var i in this.components) {
      //   const component = this.components[i];
      //   if (component.type == 'text') {
      //     this.debug(component);
      //     component.text = component.originalText.join('');
      //   }
      // }
      this.$store.commit('template/tid', null);

      this.$store.commit('template/frontend_props', this.templateCopy);

      this.$store.dispatch('setTemplate', this.templateCopy);
    },
    async splitBaseTextIntoArray() {
      for (var i in this.components) {
        if (this.components[i].templateSide == 'back') {
          if (this.pages.length == 1) {
            this.pages.push('#back');
          }
          this.$store.dispatch('enableBackSide', true);
        }
        if (
          this.components[i].type == 'text' ||
          (this.components[i].type == 'image' &&
            this.components[i].dynamicImage)
        ) {
          // this.$log.debug(this.components[i]);
          // .filter(function(el) {
          //   return el;
          // });
          //  /((?:\[[A-Z0-9 ]+\]))|([a-zA-Z0-9]+)/g
          if (this.components[i].fontFamily) {
            this.currentFonts.add(this.components[i].fontFamily);
          }
          this.components[i].baseText = this.components[i].text.match(
            // eslint-disable-next-line no-useless-escape
            /(\[[A-Z0-9 ]+\])|([a-zA-Z0-9!@#$&()\\-`.+,/\"]*)/g
          );
          this.components[i].originalText = this.components[i].text.match(
            // eslint-disable-next-line no-useless-escape
            /(\[[A-Z0-9 ]+\])|([a-zA-Z0-9!@#$&()\\-`.+,/\"]*)/g
          );
          // .match(
          //   /\[[A-Z0-9 ]*\]/g
          // );

          // this.$log.debug(this.components[i].baseText);
        }
        if (this.components[i].type == 'image') {
          // console.log(this.isNFT());
          // console.log(!this.components[i].src.startsWith('https:'));
          // console.log(this.components[i].id === 0);

          if (
            this.isNFT() &&
            !this.components[i].src.startsWith('https:') &&
            this.components[i].id === 0
          ) {
            this.components[i].src = await this.toDataURL(
              `${API_URL_IMAGES}/img/stamp-wallid.png`
            );
          } else {
            console.log('components[i]', this.components[i]);
            this.components[i].src = await this.toDataURL(
              this.components[i].src
            );
          }
        }
      }

      this.templateValuesFront.forEach((item) => {
        // this.$log.debug(item);

        item.indexArray = this.findAttr(item);
      });
      this.templateValuesBack.forEach((item) => {
        // this.$log.debug(item);

        item.indexArray = this.findAttr(item);
      });
      this.templateValuesFront.sort(this.compareAttr);
      this.templateValuesBack.sort(this.compareAttr);

      this.$log.debug('templateValuesFront', this.templateValuesFront);
      this.$log.debug('templateValuesBack', this.templateValuesBack);
    },

    compareAttr(a, b) {
      if (a.attr < b.attr) {
        return -1;
      }
      if (a.attr > b.attr) {
        return 1;
      }
      return 0;
    },
    findAttr(item) {
      let indexAttr, indexComponent;

      for (var i in this.components) {
        if (
          (this.components[i].type == 'text' &&
            this.components[i].baseText &&
            this.components[i].baseText.length > 0) ||
          (this.components[i].type == 'image' &&
            this.components[i].dynamicImage)
        ) {
          indexAttr = this.components[i].baseText.findIndex((el) => {
            // this.$log.debug(el);
            // this.$log.debug(item.attr);
            // this.$log.debug('[' + item.attr + ']' == el);
            return el.includes('[' + item.attr + ']');
          });
          // this.$log.debug(indexAttr);
          if (indexAttr > -1) {
            indexComponent = i;
            break;
          }
        }
      }
      return { indexComponent, indexAttr };
    },
    replaceAttributeImage(component) {
      this.$log.debug('START REPLACE FUNCTION');
      this.$log.debug(component);
      let indexComponent;

      indexComponent = component.indexArray.indexComponent;

      this.components[indexComponent].src = component.value;
      this.$forceUpdate();
    },
    replaceAttributeText(component) {
      this.$log.debug('START REPLACE FUNCTION');
      this.$log.debug(component);
      if (this.customTemplateName == 'templateEditor') {
        let indexAttr, indexComponent;

        indexAttr = component.indexArray.indexAttr;
        indexComponent = component.indexArray.indexComponent;

        this.$log.debug(this.components[indexComponent]);
        // Get Base text
        let baseText = this.components[indexComponent].baseText;

        if (component.value) {
          baseText[indexAttr] = component.value;
          this.$log.debug(baseText);
        } else {
          baseText[indexAttr] =
            this.components[indexComponent].originalText[indexAttr];
        }
        if (this.components[indexComponent].maxCharacters)
          component.maxCharacters =
            this.components[indexComponent].maxCharacters;

        let newValue = baseText.join(' ');
        // this.$log.debug(newValue);
        this.components[indexComponent].text = newValue;
        this.$forceUpdate();
      }
    },

    createTemplateItems() {
      // this.$log.debug(this.templateItens);
      this.templateItens.forEach((e) => {
        if (e.order == 0) {
          this.templateValuesFront.push({
            attr: e.attr,
            value: null,
            error: false,
            type: e.type,
            temp_item_id: e._id,
            isPublic: e.isPublic,
            isMandatory: e.isMandatory,
          });
        } else if (e.order == 1) {
          this.templateValuesBack.push({
            attr: e.attr,
            value: null,
            error: false,
            type: e.type,
            temp_item_id: e._id,
            isPublic: e.isPublic,
            isMandatory: e.isMandatory,
          });
        }
      });
    },

    createOldTemplate() {
      this.currentColor = this.frontend_props
        ? this.frontend_props.color
        : null;
      this.templateItens.forEach((e) => {
        if (e.attrFormat == KEYVAL) {
          this.frontTemplate.push({
            attr: e.attr,
            value: null,
            error: false,
            type: e.type,
            temp_item_id: e._id,
            isPublic: e.isPublic,
            isMandatory: e.isMandatory,
            order: e.order,
          });
          this.templateValuesFront.push({
            attr: e.attr,
            value: null,
            error: false,
            type: e.type,
            temp_item_id: e._id,
            isPublic: e.isPublic,
            isMandatory: e.isMandatory,
          });
        } else if (e.attrFormat == TABLE) {
          this.tables.push({
            headers: e.table_headers,
            values: e.table_attr,
            item_id: e._id,
          });
          this.backTemplate = {
            headers: e.table_headers,
            values: e.table_attr,
          };
        } else if (e.attrFormat == LOGOS) {
          this.$log.debug(e);
          this.logos = e.logos;
        } else if (e.attrFormat == SIGS) {
          this.$log.debug(e);
          this.sigs = e.sigs;
        }
      });

      this.frontTemplate.sort(this.compare);

      this.tables.forEach((t, i) => {
        this.tableLine.push([]);
        this.$log.debug(t);

        t.headers.forEach((e) => {
          this.$log.debug(e);

          let el = {
            attr: e.text,
            input: e.value,
            value: null,
            error: false,
          };
          el[e.value] = null;
          this.$log.debug(el);

          this.tableLine[i].push(el);
        });
      });
      this.debug(this.templateValues);
      this.debug(this.frontTemplate);
      this.debug(this.tableLine);
    },

    OLDtoDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url + '?_=' + Date.now(), true);
      xhr.responseType = 'blob';
      xhr.send();
    },
    async toDataURL(url) {
      console.log('toDataURL', url);
      if (url.startsWith('data:image')) return url;

      return await new Promise((resolve) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = function () {
          resolve(undefined);
          console.error('** An error occurred during the XMLHttpRequest');
        };
        xhr.open('GET', url + '?_=' + Date.now(), true);
        xhr.responseType = 'blob';
        xhr.send();
      });
      // var xhr = new XMLHttpRequest();
      // xhr.onload = function () {
      //   var reader = new FileReader();
      //   reader.onloadend = function () {
      //     callback(reader.result);
      //   };
      //   reader.readAsDataURL(xhr.response);
      // };
      // xhr.open('GET', url + '?_=' + Date.now(), true);
      // xhr.responseType = 'blob';
      // xhr.send();
    },
    async replaceImagesURL() {
      // testind if this is required or not
      // this.$nextTick(() => {
      this.photoURLBase64 = await this.toDataURL(this.urlPhoto);

      this.frontend_props.background_url = await this.toDataURL(
        this.frontend_props.background_url
      );
      window.scroll(0, 0);
      //Wait for Vue update
      // this.$nextTick(() => this.print(next));
      // });
    },
    compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const valA = a.order;
      const valB = b.order;

      let comparison = 0;
      if (valA > valB) {
        comparison = 1;
      } else if (valA < valB) {
        comparison = -1;
      }
      return comparison;
    },
    isNFT() {
      // console.log(this.adminEmail);
      return (
        this.frontend_props &&
        this.frontend_props.currentLayout &&
        this.frontend_props.currentLayout === 'NFT'
      );
    },
    removeItem(index) {
      this.tableValues.splice(index, 1);
    },
    addTableLine(indexT) {
      this.$log.debug(this.tableLine[indexT]);
      let el = {};
      let error = false;
      this.tableLine[indexT].forEach((e, index) => {
        if (e.value) {
          this.tableLine[indexT][index].error = false;
          el[e.input] = e.value;
          e.value = null;
        } else {
          this.tableLine[indexT][index].error = true;
          error = true;
        }
      });
      if (!error) this.tableValues.push(el);
    },
    clearErrorTable(indexT, indexL) {
      this.tableLine[indexT][indexL].error = false;
    },
    clearError(index) {
      if (
        this.templateValuesFront[index] &&
        this.templateValuesFront[index].value
      ) {
        this.templateValuesFront[index].error = false;
      } else if (
        this.templateValuesBack[index] &&
        this.templateValuesBack[index].value
      ) {
        this.templateValuesBack[index].error = false;
      } else {
        this.templateValuesFront[index].error = true;
      }
    },
    checkForm() {
      this.debug('Check form');
      let error = false;
      this.templateValuesFront.forEach((e) => {
        if ((e.isMandatory == 'true' || e.isMandatory == true) && !e.value) {
          e.error = true;
          error = true;
        } else {
          e.error = false;
        }
      });
      this.templateValuesBack.forEach((e) => {
        if ((e.isMandatory == 'true' || e.isMandatory == true) && !e.value) {
          e.error = true;
          error = true;
        } else {
          e.error = false;
        }
      });
      if (this.tables && this.tables.length > 0 && this.tableValues.length == 0)
        error = true;

      return error;
    },
    async createData() {
      let templateValuesFrontMapped = this.templateValuesFront.map((e) => {
        console.log(e);
        // if (e.type === 'image') {
        //   return this.uploadPhoto(e.file, this.tid).then((url) => {
        //     return {
        //       value: url,
        //       temp_item_id: e.temp_item_id,
        //       isPublic: e.isPublic,
        //     };
        //   });
        // } else {
        return {
          value: e.value,
          temp_item_id: e.temp_item_id,
          isPublic: e.isPublic,
        };
        // }
      });

      await Promise.all(templateValuesFrontMapped).then(function (results) {
        templateValuesFrontMapped = results;
      });
      let templateValuesBackMapped = this.templateValuesBack.map((e) => {
        // if (e.type === 'image') {
        //   return this.uploadPhoto(e.file, this.tid).then((url) => {
        //     return {
        //       value: url,
        //       temp_item_id: e.temp_item_id,
        //       isPublic: e.isPublic,
        //     };
        //   });
        // } else {
        return {
          value: e.value,
          temp_item_id: e.temp_item_id,
          isPublic: e.isPublic,
        };
        // }
      });

      await Promise.all(templateValuesBackMapped).then(function (results) {
        templateValuesBackMapped = results;
      });
      this.data = templateValuesFrontMapped.concat(templateValuesBackMapped);
      if (
        this.tables &&
        this.tables.length > 0 &&
        this.tableValues.length > 0
      ) {
        this.data.push({
          values: this.tableValues,
          temp_item_id: this.tables[0].item_id,
          isPublic: true,
        });
      }
      this.$log.debug('Generated Data: ', this.data);
    },
    close() {
      this.$emit('close');
    },
    back() {
      this.step = 0;
      this.buttonText = this.$t('button.next');
      this.email = null;
      // this.hasWallet = -1;
      this.editable = true;
    },
    async nextStep() {
      try {
        switch (this.step) {
          case 0:
            if (!this.checkForm()) {
              await this.createData();
              this.step += 1;
              this.buttonText = this.$t('button.issue');
              this.editable = false;
              this.sending = false;
            } else {
              this.sending = false;
            }
            break;
          case 1:
            this.debug('Emit cert');
            this.sending = true;
            this.errorMail = null;
            this.reload = false;
            if (this.email || this.walletAddress) {
              this.$nextTick(async () => {
                this.reload = true;
                this.$nextTick(async () => {
                  let imgArray = await generateImages(
                    this.pages,
                    this.tid,
                    this.frontend_props.currentLayout !== 'Card'
                      ? '1080'
                      : '480',
                    this.frontend_props.currentLayout
                  );

                  await this.$store
                    .dispatch(ISSUE_USER, {
                      tid: this.tid,
                      email: this.email,
                      data: this.data,
                      imgArray,
                    })
                    .then(() => {
                      this.showSuccessModal = true;
                    })
                    .catch((err) => {
                      console.error(err);
                      this.sending = false;
                    });
                });
              });
            } else {
              this.errorMail = this.$t('issue.emailError');
            }
            // }
            break;

          default:
            break;
        }
      } catch (error) {
        console.error(error);
        // } finally {
        //   this.sending = false;
      }
    },
  },
  data() {
    return {
      // Data to delete
      customTemplate: { 'wallid.io': true },

      currentColor: null,

      editable: true,
      backTemplate: null,
      frontTemplate: [],

      tableValues: [],
      tableLine: [],
      tables: [],
      logos: [],
      sigs: [],

      // Currently used
      step: 0,
      isLoaded: false,
      imageLoading: false,

      hasWallet: 0,
      email: null,
      errorMail: null,

      reload: true,
      sending: false,
      walletAddress: null,

      components: [],
      templateValuesFront: [],
      templateValuesBack: [],
      templateCopy: null,
      photoURLBase64: null,

      buttonText: this.$t('button.next'),
      showSuccessModal: false,
      pages: ['#page'],

      currentFonts: new Set(),

      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || this.$t('issue.emailError'),
      ],
    };
  },
};
</script>

<style lang="scss">
// Styles for canvas

.issue-form-wrapper {
  max-height: 70vh;
}
.page {
  textarea {
    overflow: hidden;
  }
}

.issue-cred {
  .template-background {
    background-color: #e2e2e2;
  }
  .passepartout {
    padding-top: 0.8rem;
  }
  .v-stepper {
    box-shadow: none;
  }
  .confirm.v-btn {
    background-color: #00808e !important ;
  }
  .modal-body.scroll {
    max-height: 420px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .static_image {
    height: 265px;
    max-width: 376px;
    background: white;
    position: relative;
    border-radius: 18px;

    margin: 50px auto;

    .id-card {
      position: absolute;
      margin-left: -12px;
      margin-right: -12px;
    }
  }
  .input-field {
    display: flex;
    flex-direction: column;
    .advance-btn.upload {
      max-width: 150px;
      svg {
        margin-top: 0 !important;
      }
    }
    .v-input--radio-group {
      .v-input__slot {
        border: none !important;
      }
    }
    .v-input__control {
      min-height: unset;
      .v-input__slot {
        margin-bottom: 4px;
        height: 44px;
        border-radius: 3px;
        border: solid 1px var(--light-grey-blue);
      }
      .v-text-field__details {
        margin-bottom: 4px;
      }
    }
  }
  div.steps {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--teal-blue);
  }
  h2.title_header,
  div.steps {
    display: inline;
  }
  .side-header {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    text-transform: uppercase;
  }
}
</style>
